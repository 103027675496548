export const FULL_PERMISSIONS = {
  ACTIVITY_CREATE: {
    name: 'activity:create',
    category: 'activity',
  },
  ACTIVITY_VIEW: {
    name: 'activity:view',
    category: 'activity',
  },
  ACTIVITY_STATUS_UPDATE: {
    name: 'activity:status:update',
    category: 'activity',
  },
  ACTIVITY_EXPORT: {
    name: 'activity:export',
    category: 'activity',
  },
  ACTIVITY_STREAM_IMPORT: {
    name: 'activity:stream:import',
    category: 'activity',
  },
  ACTIVITY_ARCHIVE: {
    name: 'activity:archive',
    category: 'activity',
  },
  PROVIDER_GENERAL_VIEW: {
    name: 'provider:general:view',
    category: 'provider:view',
  },
  PROVIDER_FINANCE_VIEW: {
    name: 'provider:finance:view',
    category: 'provider:view',
  },
  PROVIDER_HOWITWORKS_VIEW: {
    name: 'provider:howitworks:view',
    category: 'provider:view',
  },
  PROVIDER_CUSTOMATTRIBUTES_VIEW: {
    name: 'provider:customattributes:view',
    category: 'provider:view',
  },
  PROVIDER_FAQ_VIEW: {
    name: 'provider:faq:view',
    category: 'provider:view',
  },
  PROVIDER_ACTIVITYINSTRUCTIONS_VIEW: {
    name: 'provider:activityinstructions:view',
    category: 'provider:view',
  },
  PROVIDER_ENTITLEMENTS_VIEW: {
    name: 'provider:entitlements:view',
    category: 'provider:view',
  },
  PROVIDER_AUDIT_VIEW: {
    name: 'provider:audit:view',
    category: 'provider:view',
  },
  PROVIDER_GENERAL_EDIT: {
    name: 'provider:general:edit',
    category: 'provider:edit',
  },
  PROVIDER_FINANCE_EDIT: {
    name: 'provider:finance:edit',
    category: 'provider:edit',
  },
  PROVIDER_HOWITWORKS_EDIT: {
    name: 'provider:howitworks:edit',
    category: 'provider:edit',
  },
  PROVIDER_CUSTOMATTRIBUTES_EDIT: {
    name: 'provider:customattributes:edit',
    category: 'provider:edit',
  },
  PROVIDER_FAQ_EDIT: {
    name: 'provider:faq:edit',
    category: 'provider:edit',
  },
  PROVIDER_ACTIVITYINSTRUCTIONS_EDIT: {
    name: 'provider:activityinstructions:edit',
    category: 'provider:edit',
  },
  PROVIDER_ENTITLEMENTS_EDIT: {
    name: 'provider:entitlements:edit',
    category: 'provider:edit',
  },
  PROVIDER_AUDIT_EDIT: {
    name: 'provider:audit:edit',
    category: 'provider:edit',
  },
  PROVIDER_CREATE: {
    name: 'provider:create',
    category: 'provider',
  },

  EMPLOYER_GENERAL_VIEW: {
    name: 'employer:general:view',
    category: 'employer:view',
  },
  EMPLOYER_FINANCE_VIEW: {
    name: 'employer:finance:view',
    category: 'employer:view',
  },
  EMPLOYER_HOWITWORKS_VIEW: {
    name: 'employer:howitworks:view',
    category: 'employer:view',
  },
  EMPLOYER_CUSTOMATTRIBUTES_VIEW: {
    name: 'employer:customattributes:view',
    category: 'employer:view',
  },
  EMPLOYER_FAQ_VIEW: {
    name: 'employer:faq:view',
    category: 'employer:view',
  },
  EMPLOYER_ACTIVITYINSTRUCTIONS_VIEW: {
    name: 'employer:activityinstructions:view',
    category: 'employer:view',
  },
  EMPLOYER_ENTITLEMENTS_VIEW: {
    name: 'employer:entitlements:view',
    category: 'employer:view',
  },
  EMPLOYER_AUDIT_VIEW: {
    name: 'employer:audit:view',
    category: 'employer:view',
  },
  EMPLOYER_GENERAL_EDIT: {
    name: 'employer:general:edit',
    category: 'employer:edit',
  },
  EMPLOYER_FINANCE_EDIT: {
    name: 'employer:finance:edit',
    category: 'employer:edit',
  },
  EMPLOYER_HOWITWORKS_EDIT: {
    name: 'employer:howitworks:edit',
    category: 'employer:edit',
  },
  EMPLOYER_CUSTOMATTRIBUTES_EDIT: {
    name: 'employer:customattributes:edit',
    category: 'employer:edit',
  },
  EMPLOYER_FAQ_EDIT: {
    name: 'employer:faq:edit',
    category: 'employer:edit',
  },
  EMPLOYER_ACTIVITYINSTRUCTIONS_EDIT: {
    name: 'employer:activityinstructions:edit',
    category: 'employer:edit',
  },
  EMPLOYER_ENTITLEMENTS_EDIT: {
    name: 'employer:entitlements:edit',
    category: 'employer:edit',
  },
  EMPLOYER_AUDIT_EDIT: {
    name: 'employer:audit:edit',
    category: 'employer:edit',
  },
  EMPLOYER_CREATE: {
    name: 'employer:create',
    category: 'employer',
  },
  FEATURES_EDIT: {
    name: 'features:edit',
    category: 'features',
  },
  FEATURES_VIEW: {
    name: 'features:view',
    category: 'features',
  },
  REQUESTS_VIEW: {
    name: 'request:view',
    category: 'request',
  },
  REQUESTS_EDIT: {
    name: 'request:edit',
    category: 'request',
  },
  ANALYTICS_VIEW: {
    name: 'analytics:view',
    category: 'analytics',
  },
  PERMISSIONS: {
    name: 'permissions',
    category: 'permissions',
  },
};

// Create an object with just the permission names
export const PERMISSIONS = Object.keys(FULL_PERMISSIONS).reduce((acc, key) => {
  acc[key] = FULL_PERMISSIONS[key].name;
  return acc;
}, {});

// Build the hierarchical permissions structure
export const PERMISSIONS_BY_CATEGORY = Object.values(FULL_PERMISSIONS).reduce((acc, permission) => {
  const [category, subcategory] = permission.category.split(':');

  if (!acc[category]) {
    acc[category] = {};
  }

  if (subcategory) {
    if (!acc[category][subcategory]) {
      acc[category][subcategory] = [];
    }
    acc[category][subcategory].push(permission.name);
  } else {
    if (!acc[category].general) {
      acc[category].general = [];
    }
    acc[category].general.push(permission.name);
  }

  return acc;
}, {});
