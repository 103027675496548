import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui-labs/wizard/d2l-single-step-header.js';
import '@brightspace-ui/core/components/button/button-subtle.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-textarea.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';
import '@brightspace-ui/core/components/icons/icon.js';

import { bodyCompactStyles, bodySmallStyles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement, nothing } from 'lit';
import { formatIsoDate, getLocalizedDateIgnoreTimezone } from '../../../../../shared/helpers/dateTime.js';
import { novaLocalize } from '../../../../../shared/l10n/localize.js';

import { Application, ApplicationHelpers, statuses } from '../../../../../shared/models/application/index.js';
import { APPROVAL_MODELS, BUDGET_CONFIGURATIONS } from '../../../../../shared/models/schema/tenant/index.js';
import Activity from '../../../../../shared/models/activity/activity.js';
import { Cost } from '../../../../../shared/models/cost.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { localizeStartDate } from '../../../../../shared/models/schema/activity/shared.js';

import { DEFAULT_CURRENCY, SUBMIT_MY_OWN_ACTIVITY_ID } from '../../../../../shared/constants.js';

import '../../../../shared/components/general/app-link/app-link.js';
import '../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../../../../shared/components/general/detail-list/detail-list.js';
import '../../../../shared/components/general/nova-card/nova-card.js';
import { ATTRIBUTE_DISPLAY_OPTIONS } from '../../../../../shared/models/attribute-display-options.js';
import attributeDefinitions from '../../../../../shared/models/nova-attribute-definitions.js';

const { CORPORATE_POLICY, CASE_BY_CASE, PERCENT_COVERAGE } = BUDGET_CONFIGURATIONS;

const wizardLabels = [
  'application-overview.wizardLabels.step.1',
  'application-overview.wizardLabels.step.2',
];

class ApplicationOverview extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      activity: { type: Object, reflect: false },
      application: { type: Object, reflect: false },
      approverList: { type: Array, reflect: false },
      providerCurrency: { type: String, reflect: false },
      providerName: { type: String, reflect: false },
      provider: { type: Object, reflect: false },
      employer: { type: Object, reflect: false },
      isMyApplication: { type: Boolean, reflect: false },
      taxPercent: { type: Number },
      _approvedAmountConfirmed: { type: Boolean, attribute: false },
      _approvedReason: { type: String, attribute: false },
      _cancelReason: { type: String, attribute: false },
      _declinedReason: { type: String, attribute: false },
      _openApproveRequestConfirmation: { type: Boolean, attribute: false },
      _openDeclineRequestConfirmation: { type: Boolean, attribute: false },
      _openRevokeRequestConfirmation: { type: Boolean, attribute: false },
      _isMaxAmount: { type: Boolean, attribute: false },
      _isMinAmount: { type: Boolean, attribute: false },
      _isBudgetHolder: { type: Boolean, attribute: false },
      _isApprovalAmountEditEnabled: { type: Boolean, attribute: false },
      _approvedAmountInProviderCurrency: { type: Object, attribute: false },
      _isPaymentHandledByProvider: { type: Boolean, attribute: false },
      _invalidAmountEntered: { type: Boolean, attribute: false },
      _editableApprovedAmount: { type: Object, reflect: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      heading3Styles,
      offscreenStyles,
      linkStyles,
      super.styles,
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        .alert-subtext {
          margin: 0;
        }

        .activity-status {
          display: grid;
          grid-template-columns: 3fr auto;
        }

        .activity-type {
          align-self: center;
          grid-column: none;
          height: fit-content;
          height: -moz-fit-content;
          width: fit-content;
        }

        .approval-amount-confirmation {
          margin: 12px 0 24px;
        }

        .approval-heading, .enrollment-heading {
          margin-bottom: 16px;
        }

        .approval-reason {
          height: 150px;
          width: 100%;
        }

        .application-overview-card {
          display: block;
          overflow: unset;
          z-index: unset;
        }

        .card-content {
          justify-content: left;
        }

        .card-content > img {
          max-width: 100%;
        }

        .currency-code {
          line-height: 42px;
          margin: 0 20px;
        }

        d2l-button:not(:first-child) {
          margin-left: 10px;
        }

        .existing-app-alert {
          margin-bottom: 10px;
        }

        .main-header {
          margin: 0;
        }

        .wizard {
          display: flex;
          flex: 1;
          justify-content: center;
          margin: 30px 0;
          overflow-x: auto;
          width: 100%;
        }

        .approved-amount-btn-wrapper {
          display: flex;
          gap: 0.8rem;
        }

        .approval-amount-content {
          display: grid;
          margin-top: 24px;
          row-gap: 18px;
        }

        .approval-amount-info {
          align-items: center;
          column-gap: 0.8rem;
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: auto auto 1fr;
        }

        .approval-info-description {
          margin-bottom: 24px;
        }

        .start-date-icon {
          margin-top: -4px;
          padding-right: 5px;
        }

        @media (max-width: 767px) {
          .wizard {
            display: none;
          }

          .activity-title {
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.2rem;
            margin-bottom: 12px;
            margin-top: 6px;
          }

          .approval-amount-info {
            display: grid;
            row-gap: 0.8rem;
          }

          .approved-amount-btn-wrapper {
            grid-row: 2;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.activity = new Activity();
    this.application = new Application();
    this.approverList = [];
    this.providerCurrency = DEFAULT_CURRENCY;
    this.employerCurrency = DEFAULT_CURRENCY;
    this.mostRecentApp = new Application();
    this._approvedAmountConfirmed = false;
    this._approvedReason = '';
    this._cancelReason = '';
    this._declinedReason = '';
    this._openApproveRequestConfirmation = false;
    this._openDeclineRequestConfirmation = false;
    this._openRevokeRequestConfirmation = false;
    this._isOverMaxAmount = false;
    this._isBelowMinAmount = false;
    this._isBudgetHolder = false;
    this._isApprovalAmountEditEnabled = false;
    this._approvedAmountInProviderCurrency = new Cost();
    this._activityCostInEmployerCurrency = new Cost();
    this._activityCostWithTax = new Cost();
    this._isPaymentHandledByProvider = false;
    this._invalidAmountEntered = false;
    this._editableApprovedAmount = new Cost();
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this.updated = this.updated.bind(this);
    const { approvalModel, budget } = this.session.tenant;
    this._approvedAmountConfirmed = approvalModel !== APPROVAL_MODELS.hybrid || budget.type === BUDGET_CONFIGURATIONS.CORPORATE_POLICY; // If the approval method is hybrid and it's nota corporate policy, the user must confirm the amount
    this._isPaymentHandledByProvider = this.employer.hasTag('paymentHandledByProvider');
    this._editableApprovedAmount = new Cost(this.application.tempApprovedAmount);
  }

  render() {
    return html`
      <nova-card class="application-overview-card">
        <div slot="primary" class="d2l-skeletize">
          ${this._header}
          ${this._statusWizard}
          ${this._applicationOverview}
          ${this._approvalInformation}
          ${this._enrollmentInformation}
        </div>
        ${this._footer}
      </nova-card>
      ${this._dialogRequestApprove}
      ${this._dialogRequestDecline}
      ${this._dialogRequestRevoke}
      ${this._getExternalApplicationSummary()}
    `;
  }

  async updated(_changedProperties) {
    let updateCosts = false;
    for (const [propName] of _changedProperties) {
      if ((propName === 'activity'
        || propName === 'employer'
        || propName === 'taxPercent') && !this.activity.tempCost.isEmpty()
      ) {
        updateCosts = true;
      }
    }
    if (updateCosts) {
      this._isBudgetHolder = this._checkBudgetHolder();
      // Set activityCosts
      const taxMultiplier = (this.taxPercent || 0) / 100 + 1;
      // Use the application activity if the application is approved, otherwise use the activity
      const activityCost = this.application?.isApproved ? this.application.activity.tempCost : this.activity.tempCost;
      this._activityCostWithTax = activityCost.applyRate(taxMultiplier);
      this._activityCostInEmployerCurrency = await this._activityCostWithTax
        .toCurrency(this._employerOperatingCurrency);

      let approvedAmount = this.application.tempApprovedAmount;
      if (approvedAmount.isEmpty()) {
        approvedAmount = new Cost(this._activityCostInEmployerCurrency);
        if (this.session.tenant.budget.type === PERCENT_COVERAGE) {
          approvedAmount = approvedAmount
            .applyRate(this.session.tenant.budget.percentCoveragePerRequest / 100);
        }
      }
      await this._updateApprovedAmount(approvedAmount);
    }
  }

  async _updateApprovedAmount(approvedAmount) {
    this.application.tempApprovedAmount = approvedAmount;
    this._approvedAmountInProviderCurrency = this._isApprovalAmountEditEnabled ? await this._editableApprovedAmount.toCurrency(this.providerCurrency) : await this._approvedAmount.toCurrency(this.providerCurrency);
  }

  get _approvedAmount() {
    return this.application.tempApprovedAmount;
  }

  get activityLinkTemplate() {
    console.log('activity', this.activity);
    console.log('activity.id',this.activity?.id);
    if (this.activity.id === SUBMIT_MY_OWN_ACTIVITY_ID) {
      return this.activity.title;
    }
    return html`
      <d2l-link href="/activities/${this.activity?.id}" target="_blank">${this.activity?.title}</d2l-link>
    `;
  }

  get _requestInfo() {
    const displayName = this.application?.user.getDisplayName?.();
    const applicationDate = formatIsoDate(this.application?.applicationDate);
    return [
      { key: this.localize('application-overview.general.requestId'), value: this.application?.slug },
      { key: this.localize('application-overview.general.submitted'), value: this.localize('application-overview.general.submittedOn', { displayName, applicationDate }) },
    ];
  }

  get _userInfo() {
    const context = {
      isTaxable: this.application.taxable,
      user: this.application.user,
      lang: this.session.user.getSetting('language'),
      tenantType: this.session.user.tenantType,
      application: this.application,
    };
    const personalDetailsFields = attributeDefinitions.joinCustomAttributesWithDefault(
      ATTRIBUTE_DISPLAY_OPTIONS.APPLICATION_OVERVIEW,
      [...this.session.tenant.customAttributes, ...(this.provider?.customAttributes || [])],
      context);

    const applicationFields = attributeDefinitions.joinCustomAttributes(
      ATTRIBUTE_DISPLAY_OPTIONS.APPLICATION_OVERVIEW,
      this.session.tenant?.applicationAttributes,
      this.provider?.applicationAttributes,
      context);
    return [...personalDetailsFields, ...applicationFields].map(field => ({
      key: field.displayName.getTerm(this.session.user.getSetting('language')),
      value: field.value,
    }));
  }

  get _applicationInfo() {
    const activityCost = this._activityCostWithTax.formatAsDecimal(this.session?.settings?.language);
    const nextSessionValue = this.application?.nextSessionDateWhenPaid
      ? localizeStartDate(this.application?.nextSessionDateWhenPaid)
      : (this.application?.isPending || this.application?.isApprovedOnly) && this.activity?.formattedStartDate;

    return [
      { key: this.activity.getTranslatedValue('type'), value: this.activityLinkTemplate },
      ...(this._isPaymentHandledByProvider ? [] : [{ key: this.localize('application-overview.general.cost'), value: activityCost }]),
      { key: this.localize('application-overview.general.nextSession'), value: nextSessionValue },
    ];
  }

  get _applicationOverview() {
    const content = [
      ...this._requestInfo,
      ...this._userInfo,
      ...this._applicationInfo,
    ];

    if (this.session?.user?.tenantType !== 'employer' && this.session.tenant.learnerTerminology === 'employee') {
      content.splice(2, 0, { key: this.localize('general.employer'), value: this.application?.employerName });
    }

    if (this._canViewComments) {
      content.push({ key: this.localize('application-overview.general.requestReason'), value: this.application?.message });
    }

    return html`<detail-list .data=${content}></detail-list>`;
  }

  /**
   * Render approval amount with edit button for budget holders
   */
  get _approvalAmountEditState() {
    if (!this.application.isApprovedByBudgetHolder(this.employer?.budget?.budgetHolder)) {
      return html`<span>${this.localize('application-overview.approvalInfo.amount.pending')}</span>`;
    }
    const approvedAmount = this._approvedAmountInProviderCurrency.formatAsDecimal(this.session.settings.language);
    return html`
      <span>${approvedAmount}</span>
      ${this._isBudgetHolder ? html`
        <d2l-button-subtle
          ?disabled=${this._isApprovalAmountEditDisabled}
          @click=${this._changeApprovalAmountEditState}
          text=${this.localize('application-overview.approvalInfo.amount.edit')}>
        </d2l-button-subtle>` : nothing}
    `;
  }

  /**
   * Renders approval amount info when request is approved by one or both approvers for hybrid approval model
   */
  get _approvalAmountInformation() {
    return html`
      <div class="approval-amount-content">
        <span><b>${this.localize('application-overview.approvalInfo.amount')}</b></span>
        <div class="approval-amount-info">
          ${this._isApprovalAmountEditEnabled ? this._approvalAmountSaveState : this._approvalAmountEditState}
        </div>
      </div>
      `;
  }

  /**
   * Renders approval amount update state when request is approved by one or both approvers for hybrid approval model
   */
  get _approvalAmountSaveState() {
    this._isOverMaxAmount = this._approvedAmount.inCents() > this._approvedAmount.inCents();
    this._isBelowMinAmount = this._approvedAmount.isEmpty() || this._approvedAmount.inCents() < 0;
    const isInputInvalid = this._isOverMaxAmount || this._isBelowMinAmount;
    return html`
      <d2l-input-number
        id="case-by-case-coverage-edit"
        @change=${this._saveTargetValue('_editedApprovedAmount')}
        input-width="16rem"
        label=${this.localize('application-overview.approvalInfo.amount.edit.label')}
        label-hidden
        .forceInvalid=${isInputInvalid}
        min-fraction-digits="2"
        max-fraction-digits="2"
        .value=${this.application.tempApprovedAmount.inDollars()}
        required>
        <span class="currency-code" slot="after">${this._employerOperatingCurrency}</span>
      </d2l-input-number>
      ${this._isOverMaxAmount ? html`
        <d2l-tooltip for="case-by-case-coverage-input" state="error" align="start">
          ${this.localize('application-overview.approvalInfo.maxAllowedCoverage.tooltip')}
        </d2l-tooltip>` : nothing}
        ${this._isBelowMinAmount ? html`
        <d2l-tooltip for="case-by-case-coverage-input" state="error" align="start">
          ${this.localize('application-overview.approvalInfo.minAllowedCoverage.tooltip')}
        </d2l-tooltip>` : nothing}
      <div class="approved-amount-btn-wrapper">
        <d2l-button-subtle
          ?disabled=${this._invalidAmountEntered}
          @click=${this._handleEditableApprovalAmountSave}
          text=${this.localize('application-overview.approvalInfo.amount.save')}>
        </d2l-button-subtle>
        <d2l-button-subtle
          @click=${this._approvalAmountEditStateCancel}
          text=${this.localize('application-overview.approvalInfo.amount.edit.cancel')}>
        </d2l-button-subtle>
      </div>
    `;
  }

  get _employerOperatingCurrency() {
    return this.employer?.operatingCurrency;
  }

  get _approvalInformation() {
    if (this.application?.completionStatus?.toLowerCase() === 'cancelled') {
      return nothing;
    }
    const isEnabled = (!this.application?.isPending || (this.application.hasManagerResponded?.() && this.session.user.hasEntitlement('sponsor'))) ||
      (this.session.isHybridSession);

    const approvedAmount = this._approvedAmountInProviderCurrency?.formatAsDecimal(this.session.settings.language) || 0;

    const commonContent = [{ key: this.localize('application-overview.approvalInfo.amount'), value: approvedAmount }];
    return (this._canViewComments && isEnabled) ? html`
      <h3 class="d2l-heading-3 approval-heading">${this.localize('application-overview.approvalInfo.heading')}</h3>
      ${!this.session.isHybridSession ? html`
        ${this.application?.sponsorApproval?.approvedBy === this.application?.managerApproval?.approvedBy ? html`
          ${this._approvalInformationSponsor}
        ` : html`
          ${this._approvalInformationManager}
          ${this._approvalInformationSponsor}
        `}
        ${this._isPaymentHandledByProvider ? nothing : html`<detail-list .data=${commonContent}></detail-list>`}` : this._approvalInformationHybridModel}` : nothing;
  }

  /**
   * Renders description of approval information for hybrid approval model
   */
  get _approvalInformationDescription() {
    const employeeName = this.application.user.firstName || this.application.user.displayName;
    const budgetHolder = this.session?.tenant?.budget?.budgetHolder;
    const description = budgetHolder ? 'application-overview.approvalInfo.description.hybridBudgetHolder' : 'application-overview.approvalInfo.description.hybridNonBudgetHolder';
    return this.application?.isPending ? html`
      <div class="approval-info-description" data-testId="approval-information">
        <span>${novaLocalize(description, { employeeName, budgetHolder })}</span>
      </div>
      ` : nothing;
  }

  /**
   * Render approval information for hybrid approval model
   */
  get _approvalInformationHybridModel() {
    const budgetType = this.session.tenant.budget.type;
    const showApprovalAmount = !this._isPaymentHandledByProvider && ((this.isMyApplication && this.application.isApproved) ||
      (this._isApprover && budgetType !== CORPORATE_POLICY) || (budgetType === CORPORATE_POLICY && this.application.isApproved));
    return html`
      ${this._approvalInformationDescription}
      ${this._approvalInformationHybridModelApproverRoles}
      ${showApprovalAmount ? this._approvalAmountInformation : nothing}
    `;
  }

  /**
   * Renders approvers name and their comments for hybrid approval model
   */
  get _approvalInformationHybridModelApproverRoles() {
    const approvers = ['manager', 'sponsor'];
    const { user } = this.application;
    const budgetHolder = this.session.tenant?.budget?.budgetHolder;
    const capitalizeFirstLetter = role => role[0].toUpperCase() + role.slice(1);
    const managerName = user.managerName || user.managerEmail;
    const hasManagerResponded = this.application.hasManagerResponded?.();
    const hasSponsorResponded = this.application.hasSponsorResponded?.();

    const approversInfo = approvers.map(role => {
      if (!hasManagerResponded && !hasSponsorResponded) {
        return nothing;
      }
      let value = '';
      const approverRole = this.localize(`application-overview.approvalInfo.hybridCBC.${role}`);
      const key = role !== budgetHolder ? approverRole.split(' ')[0] : approverRole;
      const isSponsor = this.session.user.hasEntitlement('sponsor');
      if (role === 'manager') {
        value = managerName;
      } else if (hasSponsorResponded) {
        value = this.application.sponsorApproval?.approverName;
      } else if (isSponsor) {
        value = this.session.user.displayName;
      }
      const content = [{ key, value }];
      return value ? html`<detail-list .data=${content}></detail-list>` : nothing;
    });

    const approversComments = approvers.map(role => {
      const roleUpperCase = capitalizeFirstLetter(role);
      const hasApproverResponded = this.application[`has${roleUpperCase}Responded`]?.();
      const key = this.localize(`application-overview.approvalInfo.${role}Comments`);
      const value = hasApproverResponded ? this.application[`${role}Approval`]?.approvalReason : '';
      return hasApproverResponded ? html`<detail-list data-testId="approval-reason" .data=${[{ key, value }]}></detail-list>` : nothing;
    });
    return html`
      ${approversInfo}
      ${approversComments}
    `;
  }

  getApproverDisplayName(approver) {
    return approver?.approverName || approver?.approvedBy;
  }

  get _approvalInformationManager() {
    const content = [
      { key: this.localize('application-overview.approvalInfo.manager'), value: this.getApproverDisplayName(this.application?.managerApproval) },
      { key: this.localize('application-overview.approvalInfo.managerComments'), value: this.application?.managerApproval?.approvalReason },
    ];
    return this.application.hasManagerResponded?.() ? html`
      <detail-list .data=${content}></detail-list>
    ` : nothing;
  }

  get _approvalInformationSponsor() {
    const content = [
      { key: this.localize('application-overview.approvalInfo.sponsor'), value: this.getApproverDisplayName(this.application?.sponsorApproval) },
      { key: this.localize('application-overview.approvalInfo.sponsorComments'), value: this.application?.sponsorApproval?.approvalReason },
    ];
    return this.application.hasSponsorResponded?.() ? html`
      <detail-list .data=${content}></detail-list>
    ` : nothing;
  }

  get _revokeData() {
    return {
      activityTitle: this.activity?.title,
      activityType: this.activity.getTranslatedValue('type'),
      requestedApprovers: this.approverList,
    };
  }

  get _canApprove() {
    const { user } = this.session;
    const { managerApproval, sponsorApproval } = this.application;

    const currentUserApproved = (managerApproval?.approvedBy === user.userId) || (sponsorApproval?.approvedBy === user.userId);
    return this.application.isPending
      && !this.application?.completionStatus
      && !currentUserApproved
      && this._isApprover;
  }

  get _canRevoke() {
    const isAdmin = this.session.tenant.type === 'admin';
    const isAppOwnerOnRevokeTenant = this.session.user.guid === this.application.user.guid;
    const isRevokeableAppState = !this.application?.completionStatus;
    const isNotDeclined = !this.application?.isDeclined;
    return (isAdmin || isAppOwnerOnRevokeTenant) && isRevokeableAppState && isNotDeclined;
  }

  get _canViewComments() {
    return (this.session.user.userId === this.application?.user.userId) || this._isApprover;
  }

  get _dialogData() {
    const activityCost = this._activityCostInEmployerCurrency.formatAsDecimal(this.session.settings.language);

    let activityCostTooltipText = null;
    if (this.employerCurrency !== this.providerCurrency) {
      activityCostTooltipText = html`
      <b>${this.localize('activity-fees.coverage.tooltip.target', { coverage: this._activityCostWithTax.formatAsDecimal(this.session.settings.language) })}</b>`;
    }
    const budgetHolder = this.session.tenant?.budget.budgetHolder;
    const isApprovedByBudgetHolder = this.application.isApprovedByBudgetHolder(budgetHolder);
    const approvedAmount = isApprovedByBudgetHolder
      ? this.application.tempApprovedAmount.formatAsDecimal(this.session.settings.language)
      : this.localize('confirmation-dialog.content.pending');

    return {
      ...(this._isPaymentHandledByProvider ? {} : { activityCost }),
      ...(this._isPaymentHandledByProvider ? {} : { activityCostTooltipText }),
      activityTitle: this.activity?.title,
      activityType: this.activity.getTranslatedValue('type'),
      username: this.application?.user.getDisplayName?.(),
      approvedAmountData: this._isPaymentHandledByProvider ? {} : { approvedAmount, isHybridCaseByCase: this.session.isHybridCaseByCase },
      learnerTerminology: this.employer?.learnerTerminology,
      approvedAmountConfirmed: !this._isBudgetHolder || this._approvedAmountConfirmed,
      isOverAmount: this._isOverMaxAmount,
      isBelowAmount: this._isBelowMinAmount,
    };
  }

  get _dialogRequestApprove() {
    const { caseByCaseTemplate, disableConfirmConditionCbc } = this._dialogRequestApproveCaseByCase;
    const { percentCoverageTemplate, disableConfirmationPct } = this._dialogRequestApprovePercentCoverage;

    return html`
      <confirmation-dialog
        ?opened=${this._openApproveRequestConfirmation}
        ?disableConfirm=${!this._approvedReason || (!this._isPaymentHandledByProvider && (disableConfirmationPct || disableConfirmConditionCbc))}
        type="approveRequestConfirmation"
        .data=${this._dialogData}
        @d2l-dialog-before-close=${this._approvalDialogClose}
        @d2l-dialog-close=${this._dialogClose('_openApproveRequestConfirmation')}>
        <div slot="below-content">
          ${this._isPaymentHandledByProvider ? nothing : caseByCaseTemplate}
          ${this._isPaymentHandledByProvider ? nothing : percentCoverageTemplate}
          <b aria-hidden="true">${this.localize('application-overview.approvalComment')}*</b>
          <d2l-input-textarea
            class="approval-reason"
            label=${this.localize('application-overview.approvalComment')}
            label-hidden
            required
            @input=${this._saveTargetValue('_approvedReason')}
            autofocus></d2l-input-textarea>
        </div>
      </confirmation-dialog>
    `;
  }

  get _dialogRequestApproveCaseByCase() {
    this._isOverMaxAmount = this._approvedAmount.inCents() > this._activityCostInEmployerCurrency.inCents();
    this._isBelowMinAmount = this._approvedAmount.inCents() < 0;
    const isInputInvalid = this._isOverMaxAmount || this._isBelowMinAmount;

    const { budget: { type: budgetType } } = this.session.tenant;

    // if approval Model is hybrid and budget type is caseBycase and tenant is not budget holder
    const isNotBudgetHolder = this.session.isHybridCaseByCase && !this._isBudgetHolder;
    if (budgetType !== CASE_BY_CASE || isNotBudgetHolder) {
      return { caseByCaseTemplate: nothing, disableConfirmCondition: false };
    }

    const caseByCaseTemplate = html`
      <b aria-hidden="true">${this.localize('application-overview.approvalInfo.amount')}*</b>
      <d2l-input-number
        id="case-by-case-coverage-input"
        @change=${this._saveTargetValue('_approvedAmount')}
        input-width="16rem"
        label=${this.localize('application-overview.approvalInfo.amount')}
        label-hidden
        .forceInvalid=${isInputInvalid}
        min-fraction-digits="2"
        max-fraction-digits="2"
        .value=${this._approvedAmount.inDollars()}
        required
        autofocus>
        <span class="currency-code" slot="after">${this._employerOperatingCurrency}</span>
        </d2l-input-number>
        ${this._isOverMaxAmount ? html`
        <d2l-tooltip for="case-by-case-coverage-input" state="error" align="start">
          ${this.localize('application-overview.approvalInfo.maxAllowedCoverage.tooltip')}
        </d2l-tooltip>` : nothing}
        ${this._isBelowMinAmount ? html`
        <d2l-tooltip for="case-by-case-coverage-input" state="error" align="start">
          ${this.localize('application-overview.approvalInfo.minAllowedCoverage.tooltip')}
        </d2l-tooltip>` : nothing}
      ${this.session.tenant.approvalModel === APPROVAL_MODELS.hybrid ? html`
      <d2l-input-checkbox
        class="approval-amount-confirmation"
        @change=${this._saveTargetCheckedValue('_approvedAmountConfirmed')}>
        ${novaLocalize('application-overview.approvalAmount.checkbox.label', { employeeName: this.application.user.getDisplayName() })}
      </d2l-input-checkbox>
      ` : nothing}
    `;

    const disableConfirmConditionCbc = !(this._approvedAmountConfirmed && this._approvedAmount.inCents() >= 0 && this._approvedAmount.inCents() <= this._activityCostInEmployerCurrency.inCents());

    return { caseByCaseTemplate, disableConfirmConditionCbc };
  }

  get _dialogRequestApprovePercentCoverage() {
    this._isOverMaxAmount = this._approvedAmount.inCents() > this._activityCostInEmployerCurrency.inCents();
    this._isBelowMinAmount = this._approvedAmount.inCents() < 0;
    const isInputInvalid = this._isOverMaxAmount || this._isBelowMinAmount;
    const budgetType = this.session.tenant?.budget?.type;

    if (budgetType !== PERCENT_COVERAGE) {
      return { caseByCaseTemplate: nothing };
    }

    // if approval model is hybrid and tenant is not budget holder
    const isNotBudgetHolder = this.session.isHybridSession && !this._isBudgetHolder;
    if (budgetType === PERCENT_COVERAGE && isNotBudgetHolder) {
      return { percentCoverageTemplate: nothing, disableConfirmCondition: false };
    }

    const percentCoverage = this.session.tenant.budget.percentCoveragePerRequest;

    const percentCoverageTemplate = html`
      <b aria-hidden="true">${this.localize('application-overview.approvalInfo.amount')}*</b>
      <d2l-input-number
        id="percent-coverage-input"
        @change=${this._saveTargetValue('_approvedAmount')}
        input-width=100%
        label=${this.localize('application-overview.approvalInfo.amount')}
        label-hidden
        .forceInvalid=${isInputInvalid}
        min-fraction-digits="2"
        max-fraction-digits="2"
        .value=${this._approvedAmount.inDollars()}
        required
        autofocus>
        <span class="currency-code" slot="after">${this._employerOperatingCurrency}</span>
        </d2l-input-number>
        ${this._isOverMaxAmount ? html`
        <d2l-tooltip for="percent-coverage-input" state="error" align="start">
        ${this.localize('application-overview.approvalInfo.maxAllowedCoverage.tooltip')}
        </d2l-tooltip>` : nothing}
        ${this._isBelowMinAmount ? html`
        <d2l-tooltip for="percent-coverage-input" state="error" align="start">
        ${this.localize('application-overview.approvalInfo.minAllowedCoverage.tooltip')}
        </d2l-tooltip>` : nothing}
        <d2l-tooltip disable-focus-lock align="start">
          ${this.localize('application-overview.approvalInfo.percentCoverage.tooltip', { percentCoverage })}
        </d2l-tooltip>
        ${this.session.tenant.approvalModel === APPROVAL_MODELS.hybrid ? html`
          <d2l-input-checkbox
            class="approval-amount-confirmation"
            @change=${this._saveTargetCheckedValue('_approvedAmountConfirmed')}>
            ${novaLocalize('application-overview.approvalAmount.checkbox.label', { employeeName: this.application.user.getDisplayName() })}
          </d2l-input-checkbox>
        ` : nothing}
        `;
    const disableConfirmationPct = !(this._approvedAmountConfirmed && this._approvedAmount.inCents() >= 0 && this._approvedAmount.inCents() <= this._activityCostInEmployerCurrency.inCents());
    return { percentCoverageTemplate, disableConfirmationPct };
  }

  get _dialogRequestRevoke() {
    return html`
      <confirmation-dialog
        ?opened=${this._openRevokeRequestConfirmation}
        ?disableConfirm=${!this._cancelReason}
        type="revokeRequestConfirmation"
        .data=${this._revokeData}
        @d2l-dialog-close=${this._dialogClose('_openRevokeRequestConfirmation')}>
        <div slot="below-content">
          <b aria-hidden="true">${this.localize('application-overview.cancelReason')} *</b>
          <d2l-input-textarea
            class="approval-reason"
            label=${this.localize('application-overview.cancelReason')}
            label-hidden
            required
            title=""
            @input=${this._saveTargetValue('_cancelReason')}></d2l-input-textarea>
        </div>
      </confirmation-dialog>
    `;
  }

  get _dialogRequestDecline() {
    return html`
      <confirmation-dialog
        ?opened=${this._openDeclineRequestConfirmation}
        ?disableConfirm=${!this._declinedReason}
        type="declineRequestConfirmation"
        .data=${this._dialogData}
        @d2l-dialog-close=${this._dialogClose('_openDeclineRequestConfirmation')}>
        <div slot="below-content">
          <b aria-hidden="true">${this.localize('application-overview.declineReason')}*</b>
          <d2l-input-textarea
            class="approval-reason"
            label=${this.localize('application-overview.declineReason')}
            label-hidden
            required
            title=""
            @input=${this._saveTargetValue('_declinedReason')}></d2l-input-textarea>
        </div>
      </confirmation-dialog>
    `;
  }

  get _isExternalApplication() {
    return this.application?.hasTag('external');
  }

  _getExternalApplicationSummary() {
    if (this._isExternalApplication) {
      const { startDate, endDate, startDateType } = this.activity;
      const localStartDate = getLocalizedDateIgnoreTimezone(startDate);
      const localEndDate = getLocalizedDateIgnoreTimezone(endDate);
      const dateRange = (startDateType === 'anytime') ? this.localize('activity.startDate.anytime') : `${localStartDate} - ${localEndDate}`;
      return html`
      <br/>
        <nova-card class="application-overview-card">
          <div slot="primary" class="d2l-skeletize">
            <div class="card-content">
              <div class="activity-status">
                <h2 class="activity-title d2l-heading-2">${this.activity.title}</h2>
                <d2l-status-indicator class="activity-type" state="none" text="${this.activity.type}" bold></d2l-status-indicator>
              </div>
              <d2l-alert type="default">
              ${this.localize('view-application.activity-details.submit-own-request.message')}
              </d2l-alert>
            </div>
          </div>
          <div slot="footer" class="d2l-skeletize">
            <div class="start-date">
              <d2l-icon class="start-date-icon" icon="tier1:calendar"></d2l-icon>
              <span class="start-date-text"><b>${this.localize('view-application.activityMetadata.footer.nextSession')}:</b></span>
              <span slot="content">${dateRange}</span>
            </div>
          </div>
        </nova-card>
      `;
    }

    return nothing;
  }

  get _enrollmentInformation() {
    const isEnabled = this.application?.isRegistrationComplete || this.application?.isCancelledBeforeRegistration;
    const employeeCancelled = `${this.session.tenant.learnerTerminology}.revoke`;
    const completionStatusKey = this.application?.cancelledBy === 'Employee' ? employeeCancelled :
      this.application?.cancelledBy === 'Admin' ? 'admin.revoke' : this.application?.completionStatus?.toLowerCase();
    const localizedEnrollmentStatus = this.localize(`application-overview.enrollmentInfo.status.${completionStatusKey}`);
    const content = [
      { key: this.localize('application-overview.enrollmentInfo.enrolledOn'), value: formatIsoDate(this.application?.enrollDate) },
      { key: this.localize('application-overview.enrollmentInfo.completedOn'), value: formatIsoDate(this.application?.completedDate) },
      { key: this.localize('application-overview.enrollmentInfo.completionStatus'), value: localizedEnrollmentStatus },
    ];
    if (this.application?.completionStatus === 'Cancelled' && this.application?.cancelReason) {
      content.push({
        key: this.localize('application-overview.enrollmentInfo.cancellationReason'),
        value: this.application.getTranslatedValue('cancelReason'),
      });
    }
    return isEnabled ? html`
      <h3 class="d2l-heading-3 enrollment-heading">${this.localize('application-overview.enrollmentInfo.heading')}</h3>
      <detail-list .data=${content}></detail-list>
    ` : nothing;
  }

  get _existingAppAlert() {
    if (!this.mostRecentApp || this.activity.hasTag('submitMyOwn')) {
      return nothing;
    }

    const appStatus = this.mostRecentApp.status.label;
    let subtext;
    switch (appStatus) {
      case statuses.CANCELLED_BEFORE_REGISTRATION.label:
        subtext = this.localize('application-overview.existingApplication.status.cancelled', { displayName: this.application.user.getDisplayName() });
        break;
      case statuses.CANCELLED_AFTER_REGISTRATION.label:
        subtext = this.localize('application-overview.existingApplication.status.cancelled', { displayName: this.providerName });
        break;
      case statuses.WITHDRAWN.label:
        subtext = this.localize('application-overview.existingApplication.status.withdrawn', { displayName: this.application.user.getDisplayName(), type: this.activity.type });
        break;
      case statuses.DECLINED.label: {
        const declinerName = ApplicationHelpers.getDeclinerName(this.mostRecentApp);
        if (declinerName) {
          subtext = this.localize('application-overview.existingApplication.status.declined', { displayName: declinerName });
        } else {
          subtext = this.localize('application-overview.existingApplication.status.declined.noDecliner');
        }
        break;
      }
      case statuses.FAILED.label:
        subtext = this.localize('application-overview.existingApplication.status.failed', { displayName: this.application.user.getDisplayName(), type: this.activity.type });
        break;
    }

    return html`
      <d2l-alert type="default" class="existing-app-alert">
        ${this.localize('application-overview.existingApplication.header', { displayName: this.application.user.getDisplayName() })}
        <p class="d2l-body-compact alert-subtext">${subtext} <app-link d2l-link href="/requests/${this.mostRecentApp.uuid}">${this.localize('application-overview.existingApplication.subtext.link')}</app-link></p>
      </d2l-alert>
    `;
  }

  get _revokeTooltip() {
    return this.application.paymentDate ? html`
      <d2l-tooltip for="cancel-button" position="bottom">${this.localize('application-overview.revokeInfo.tooltip', { provider: this.providerName })}</d2l-tooltip>
    ` : nothing;
  }

  get _footer() {
    return this._canApprove ? html`
      <div slot="footer" class="d2l-skeletize">
        ${this._existingAppAlert}
        <d2l-button primary @click=${this._dialogOpen('_openApproveRequestConfirmation')}>${this.localize('application-overview.requestConfirmation.approve')}</d2l-button>
        <d2l-button @click=${this._dialogOpen('_openDeclineRequestConfirmation')}>${this.localize('application-overview.requestConfirmation.decline')}</d2l-button>
      </div>
    ` : this._canRevoke ? html`
      <div slot="footer" class="d2l-skeletize">
        <d2l-button id="cancel-button" @click=${this._dialogOpen('_openRevokeRequestConfirmation')} ?disabled=${this.application.paymentDate}>${this.localize('application-overview.requesterAction.revoke')}</d2l-button>
        ${this._revokeTooltip}
      </div>
    ` : nothing;
  }

  get _header() {
    return html`
      <h3 class="d2l-heading-3 main-header">${this.localize('application-overview.heading')}</h3>
    `;
  }

  get _isApprovalAmountEditDisabled() {
    const { isDeclined, isApproved } = this.application;
    return isDeclined || isApproved;
  }

  get _isApprover() {
    const { user, tenantId, tenant } = this.session;
    const { requestedApprover } = this.application;
    const sameTenant = tenantId === this.application?.tenantId;

    return sameTenant
      && (user.userId === requestedApprover
        || (user.hasEntitlement('sponsor') && tenant.approvalModel !== APPROVAL_MODELS.distributed));
  }

  get _statusWizard() {
    const { wizard } = this.application.status;
    if (wizardLabels.length === 2 && this.activity?.type !== undefined) {
      wizardLabels.push(`application-overview.wizardLabels.${this.session.tenant.learnerTerminology}.step.3`);
      wizardLabels.push(`application-overview.wizardLabels.${this.activity.type}.step.4`);
    }

    return this.application.hasTag('external') ? nothing :
      html`
        <div class="wizard">
          <span class="d2l-offscreen">${this.localize('application-overview.status')}: ${this.localize(wizardLabels[wizard.labelId])}</span>
          ${wizardLabels.map((label, index) => html`
          <d2l-labs-single-step-header
            aria-hidden="true"
            total-steps=${wizardLabels.length}
            current-step=${index}
            selected-step=${wizard.step}
            title=${this.localize(label)}
            fill-header-width></d2l-labs-single-step-header>
          `)}
        </div>
      `;
  }

  _approvalAmountEditStateCancel() {
    this._invalidAmountEntered = false;
    this._editableApprovedAmount.setInDollars(this.application.tempApprovedAmount.inDollars());
    this._changeApprovalAmountEditState();
  }

  _changeApprovalAmountEditState() {
    this._isApprovalAmountEditEnabled = !this._isApprovalAmountEditEnabled;
  }

  _checkBudgetHolder() {
    // learner can not be budget holder
    if (this.isMyApplication) {
      return false;
    }
    const budgetHolder = this.employer?.budget?.budgetHolder || 'manager';
    const persona = this.session.user.getPersona().split('_')[1];
    const isMyTenant = this.application.tenantId === this.session.tenantId;
    const isBudgetHolderSponsor = budgetHolder === 'sponsor' && persona === 'sponsor';
    const isBudgetHolderManager = budgetHolder === 'manager' && persona === 'employee';
    return isMyTenant && (isBudgetHolderSponsor || isBudgetHolderManager);
  }

  _dialogClose(propertyName) {
    return async e => {
      this[propertyName] = false;
      const { action } = e.detail;
      switch (action) {
        case 'approve':
          await this._handleApprovalAmountSave(false);
          break;
        case 'decline':
          await this._updateApproval(false);
          break;
        case 'revoke':
          await this._revokeApplication();
          break;
      }
    };
  }

  async _approvalDialogClose() {
    if ((this.session.tenant.approvalModel === APPROVAL_MODELS.hybrid && this.session.tenant?.budget?.type !== 'corporatePolicy' && !this._approvedAmountConfirmed && this._isBudgetHolder) || this._isOverMaxAmount || this._isBelowMinAmount) {
      const type = this._isOverMaxAmount ? 'max' : this._isBelowMinAmount ? 'min' : 'budgetHolder';
      this._displayNotificationToast(type);
    } else {
      // this._dialogClose('_openApproveRequestConfirmation');
      this._openApproveRequestConfirmation = false;
      await this._handleApprovalAmountSave(false);
    }
  }

  _dialogOpen(propertyName) {
    return () => {
      this[propertyName] = true;
    };
  }

  _displayNotificationToast(msg) {
    let message = '';
    let type = '';
    if (msg === 'success') {
      message = this.localize('application-overview.approvalInfo.amount.updated');
      type = 'default';
    } else if (msg === 'revoke') {
      message = this.localize('application-overview.revokeInfo.cancelled', { activity: this.activity.title });
      type = 'default';
    } else if (msg === 'budgetHolder') {
      message = this.localize('application-overview.approveInfo.confirmBudgetHolder', { employeeName: this.application.user.getDisplayName() });
      type = 'critical';
    } else {
      message = this.localize(`application-overview.approvalInfo.${msg}AllowedCoverage.tooltip`);
      type = 'critical';
    }
    this.session.toast({ type, message });
  }

  async _handleEditableApprovalAmountSave() {
    await this._updateApprovedAmount(this._editableApprovedAmount);
    await this._handleApprovalAmountSave();
  }

  async _handleApprovalAmountSave(isApprovalAmountUpdated = true) {
    if (this._isOverMaxAmount) {
      return this._displayNotificationToast('max');
    } else if (this._isBelowMinAmount) {
      return this._displayNotificationToast('min');
    }
    await this._updateApproval(true, isApprovalAmountUpdated);
    if (this._isApprovalAmountEditEnabled) {
      this._changeApprovalAmountEditState();
    }
  }

  async _revokeApplication() {
    this.application = await this.client.revokeApplication(this.application.uuid, this._cancelReason);
    this._displayNotificationToast('revoke');
    this.dispatchEvent(new CustomEvent('update-application', { detail: { application: this.application } }));
  }

  _saveTargetCheckedValue(propertyName) {
    return e => {
      this[propertyName] = e.target.checked;
    };
  }

  _saveTargetValue(propertyName) {
    return async e => {
      if (propertyName === '_approvedAmount') {
        const newApprovedAmount = this._activityCostInEmployerCurrency.inDollars() === e.target.value
          ? new Cost(this._activityCostInEmployerCurrency)
          : this._approvedAmount.setInDollars(e.target.value);
        await this._updateApprovedAmount(newApprovedAmount);
      } else if (propertyName === '_editedApprovedAmount') {
        const setValue = new Cost({ dollars: e.target.value, currency: this._employerOperatingCurrency });
        const overMaxAmount = setValue.inCents() > this._activityCostInEmployerCurrency.inCents();
        const belowMinAmount = setValue.inCents() < 0;
        if (!overMaxAmount && !belowMinAmount) {
          this._invalidAmountEntered = false;
          this._activityCostInEmployerCurrency.inDollars() === e.target.value
            ? this._editableApprovedAmount.setInCents(this._activityCostInEmployerCurrency.inCents())
            : this._editableApprovedAmount.setInDollars(e.target.value);
        } else {
          this._invalidAmountEntered = true;
          if (overMaxAmount) {
            return this._displayNotificationToast('max');
          } else if (belowMinAmount) {
            return this._displayNotificationToast('min');
          }
        }
      } else {
        this[propertyName] = e.target.value;
      }
    };
  }

  async _updateApproval(approved, isApprovalAmountUpdated = false) {
    const reason = approved ? this._approvedReason : this._declinedReason;
    const approvedAmount = approved ? this._approvedAmount : 0;
    const approvalState = approved ? 'approved' : 'declined';
    this.application = await this.client.setApproval(this.application.uuid, approvedAmount, reason, approvalState);

    this._editableApprovedAmount = this.application.tempApprovedAmount;

    if (this.application && isApprovalAmountUpdated) {
      this._displayNotificationToast('success');
    }
    this.dispatchEvent(new CustomEvent('update-application', { detail: { application: this.application } }));
  }
}

window.customElements.define('application-overview', ApplicationOverview);
